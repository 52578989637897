import * as React from "react";

import Layout from "../components/Layout";

import map from "../assets/img/hero/map.png";

const ContactUs = () => {
  return (
    <Layout>
      <section className="contact-us">
        <div className="container">
          <div className="title-wrap">
            <div className="bg-text">Contact Us</div>
            <h2>
              Get in touch with us
              <br /> for any queries.
            </h2>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h3>Address</h3>
              <a
                className=""
                href="https://www.google.com/maps/place/Computer+Hut/@30.7314012,76.7743531,17.32z/data=!3m1!5s0x390feda63c7cd80b:0xf59efb756dfc7981!4m6!3m5!1s0x390fedafa5499cd5:0x51598c123bfbf5c7!8m2!3d30.7315091!4d76.7758553!16s%2Fg%2F1yj4k47h8?entry=ttu"
                target="_blank"
              >
                <p>
                  S.C.O. 1112-1113, 2nd floor,
                  <br />
                  Sector 22B, Chandigarh, 160022
                </p>
              </a>
              <h3>Phone</h3>
              <p>
                <a href="tel:+91 94652 22255">+91 94652 22255</a>
              </p>
              <h3>Email</h3>
              <p>
                <a href="mailto:info@computerhut.in">info@computerhut.in</a>
              </p>
            </div>
            <div className="col-md-6">
              <a
                className=""
                href="https://www.google.com/maps/place/Computer+Hut/@30.7314012,76.7743531,17.32z/data=!3m1!5s0x390feda63c7cd80b:0xf59efb756dfc7981!4m6!3m5!1s0x390fedafa5499cd5:0x51598c123bfbf5c7!8m2!3d30.7315091!4d76.7758553!16s%2Fg%2F1yj4k47h8?entry=ttu"
                target="_blank"
              >
                <div className="map">
                  <img src={map} alt="Map" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactUs;
